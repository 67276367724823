import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/KnowYourRights/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Know Your Rights - Early Termination Fees (ETF)'}
        description={
          "If I'm moving, do I have to pay an Early Termination Fee? Learn about when a provider can and cannot charge an Early Termination Fee."
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
