import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import AddressSearch from '../AddressSearch';

import './index.css';

function ZipCodeField({
  siteConfig,
  serviceAddress,
  buttonLabel,
  intro,
  mobile,
  hideSwitch
}) {
  const [show, setShow] = useState(false);
  const [showContract, setShowContract] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', watchScroll);

    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  function watchScroll() {
    var elementTarget = document.getElementsByTagName('h1')[0];

    setShow(
      !elementTarget ||
        window.scrollY > elementTarget.offsetTop + elementTarget.offsetHeight
    );

    if (window.scrollY > window.innerHeight) {
      setShowContract(true);
    }
  }

  return (
    <div
      className={
        'zip-entry ' + (mobile ? ' mobile' : 'desktop') + (show ? ' show' : '')
      }
      onClick={() => setShowContract(true)}>
      {!mobile && intro ? <p>{intro}</p> : null}
      <AddressSearch
        hideSearchBy
        mode="zip"
        searchButtonLabel={buttonLabel ? buttonLabel : 'Click to Compare'}
        addressLabel={'Search by Zip Code'}
        autoFocus={false}
      />
      {!hideSwitch ? (
        mobile ? (
          <div className={'contract' + (showContract ? ' show' : '')}>
            <em>In a contract?</em>
            <a class="link" onClick={() => navigate('/switch')}>
              Check if you can switch early
            </a>
          </div>
        ) : (
          <div className="contract">
            <em>In a contract?</em>
            Use our{' '}
            <a class="link" href={'/switch'}>
              Switch Calculator
            </a>{' '}
            to see if you qualify for a switch.
          </div>
        )
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeField);
